import React from "react";

export default function DownloadLink({ link, text, underline = false }) {
  return (
    <div className="py-5 px-10 show">
      <a
        href={link}
        target={"_blank"}
        className={`text-base leading-[26px] hover:text-secondary text-center block mb-[25px] text-primary font-semibold ${
          underline && "underline"
        }`}
      >
        {text}
      </a>
    </div>
  );
}
