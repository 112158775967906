import { Link } from "gatsby";
import React from "react";

const BreadCrumb = ({ path, show = "show" }) => {
  return (
    <div
      className={`relative block w-full box-border bg-cover bg-repeat-x mx-auto ${show}`}
      style={{
        backgroundImage: `linear-gradient(to bottom,rgba(102, 102, 102, 0.90),rgba(214, 214, 214, 0.90)),url(${path?.sectionBg?.asset?.url})`,
      }}
    >
      <div className="flex flex-col flex-wrap w-3/4 mx-auto justify-center px-5 py-[10px] min-h-[10vh]">
        <p className="font-bold text-xs sm:text-sm md:text-base text-white">
          <Link to="/" className="uppercase no-underline  hover:text-[#eee]">
            TRANQUILO BAY HOME
          </Link>
          {path?.breadLink?.map((item, index) => {
            return (
              <span key={index}>
                <span className="mx-2">|</span>
                <Link
                  to={item.breadPath}
                  className="uppercase no-underline hover:text-[#eee]"
                >
                  {item.nameLink}
                </Link>
              </span>
            );
          })}
        </p>
      </div>
    </div>
  );
};

export default BreadCrumb;
