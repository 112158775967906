import React, { useState, useEffect } from "react";
import { Script } from "gatsby";
const Instagram = () => {
  const [show, setShow] = useState(false);
  const [count, setCount] = useState(10);

  useEffect(() => {
    const timer = setTimeout(() => setShow(true), 10000);
    const counter = setInterval(() => {
      setCount((prev) => prev - 1);
    }, 1000);
    return () => {
      clearTimeout(timer);
      clearInterval(counter);
    };
  }, []);

  return (
    <div className="max-w-[1100px] mx-auto px-4 my-10 show-up">
      <h1 className="text-3xl uppercase sm:text-5xl text-secondary text-center  mb-4 font-bold tracking-[2px]">
        Field Notes
      </h1>
      {show ? (
        <>
          <Script src="https://apps.elfsight.com/p/platform.js" defer></Script>
          <div className="elfsight-app-e9a66c0d-38cd-434e-bd6d-89afe3273a0e"></div>
        </>
      ) : (
        <p className="text-primary text-center text-xl">
          Please Wait...<span className="text-base">{count}</span>
        </p>
      )}
    </div>
  );
};

export default Instagram;
