import React from "react";
import { GatsbyImage } from "gatsby-plugin-image";
import Slider from "react-slick";
import Button from "../buttons/Button";

const settings = {
  dots: false,
  fade: true,
  infinite: true,
  slidesToShow: 1,
  slidesToScroll: 1,
  autoplay: true,
  speed: 1000,
  autoplaySpeed: 3000,
  className: "hero-slider",
};

const Hero = ({
  heroSection,
  h1Size = "leading-[60px] text-[40px] sm:text-[53px] sm:leading-[84px]",
  h2Size = "text-3xl sm:text-[40px]",
  h1P = "",
  h2P = "pb-5",
  notFound = false,
}) => {
  return (
    <div className="relative max-w-[100vw] block align-baseline box-border z-[1] h-[650px] overflow-hidden">
      {/* Images */}

      <div className="relative block h-full">
        <Slider {...settings}>
          {heroSection?.bgImages.map((item, index) => {
            return (
              <div
                className="absolute block top-0 z-[2] before:bg-[#26262657] before:contents-[''] before:inset-0
              before:h-full before:absolute before:z-[2] before:transition-opacity duration-[80ms] ease-linear"
                key={index}
              >
                <GatsbyImage
                  image={item?.asset?.gatsbyImageData}
                  alt={item?.alt}
                  loading="lazy"
                  className="backface-hidden relative top-0 left-0 w-screen object-cover h-[650px]"
                />
              </div>
            );
          })}
        </Slider>
      </div>

      <div className="absolute block overflow-visible z-[2] -bottom-2 pointer-events-auto w-full h-auto">
        <div className="relative block max-w-[900px] mx-auto box-border">
          <div className="flex flex-col flex-wrap justify-end px-[10px] pb-10 text-white">
            {!notFound && heroSection?.heading1 && (
              <h1
                className={`${h1Size} ${h1P} font-bold word-spacing text-center uppercase font-primary`}
              >
                {heroSection?.heading1}
              </h1>
            )}
            {notFound && (
              <h1
                className={`text-[50px] sm:text-[80px] ${h1P} font-bold word-spacing text-center font-primary`}
              >
                404
              </h1>
            )}
            {!notFound && heroSection?.heading2 && (
              <h1
                className={`${h2Size} ${h2P} tracking-[2px] font-normal text-center
                  font-secondary`}
              >
                {heroSection?.heading2}
              </h1>
            )}

            <div className="relative block text-center">
              <Button
                btnText={heroSection?.ctaButton?.title}
                btnLink={heroSection?.ctaButton?.link}
                small
                btnType={heroSection?.ctaButton?.variant}
                linkType={heroSection?.ctaButton?.type}
                formId={heroSection?.ctaButton?.formId}
                height="h-[50px] sm:h-[68px]"
              />
            </div>
          </div>
        </div>

        <div className="relative block mx-auto overflow-visible box-border text-center pointer-events-auto">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            height="60"
            preserveAspectRatio="none"
            viewBox="0 0 1695 60"
            className="max-w-full w-[9999px] transition-transform duration-200 ease-out h-[60px] overflow-visible text-center"
          >
            <g>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                preserveAspectRatio="none"
                viewBox="0 0 1695 52"
              >
                <path
                  fill="#FFF"
                  d="M1695 52H0V13.095C135.4 24.405 289.6 30.06 462.5 30.06 721.9 30.06 936.7.001 1212.2.001c183.6-.06 344.5 4.345 482.8 13.095V52z"
                ></path>
              </svg>
              <path
                fill="#FFF"
                d="M0 19.643c135.4 16.964 289.6 25.446 462.5 25.446 259.4 0 474.2-45.088 749.7-45.088 183.6-.089 344.5 6.518 482.8 19.642V60H0V19.643z"
              ></path>
            </g>
          </svg>
        </div>
      </div>
    </div>
  );
};

export default Hero;
