import React from "react";
import PortableText from "react-portable-text";

export default function Security({ data }) {
  const bgUrl = `linear-gradient(${
    data?.overlay === "primary"
      ? "to bottom, rgba(102, 102, 102, 0.90),rgba(214, 214, 214, 0.90)"
      : "to bottom, rgba(192, 192, 192, 0.90),rgba(121, 121, 121, 0.90)"
  } ),url(${data.sectionBG?.asset?.url})`;

  const minH = data.height > 0 ? `min-h-[${data.height}vh]` : "min-h-auto";

  return (
    <div
      className={`relative block w-full box-border show ${
        data.textColor === "dark" ? "text-assent" : "text-white"
      } ${data.bgSize ? data.bgSize : ""} ${
        data?.bgRepeat ? data?.bgRepeat : ""
      }`}
      style={{
        backgroundImage: `${data?.sectionBG ? bgUrl : ""}`,
      }}
    >
      <div className="max-w-[1140px] mx-auto block">
        <div className={`flex flex-col flex-wrap justify-center p-5 ${minH}`}>
          <div className="relative block max-w-[1140px] w-full mx-auto">
            {data?.svg && (
              <div
                dangerouslySetInnerHTML={{ __html: data.svg }}
                className="flex justify-center"
              />
            )}
            <div className="block sm:w-3/4 mx-auto py-5">
              <p className="text-2xl sm:text-3xl font-bold uppercase text-center mb-3 leading-10 tracking-[2px]">
                {data.heading}
              </p>
            </div>
          </div>
          <div
            className={`grid grid-cols-1 gap-5 ${
              data?.columns === 2 ? "sm:grid-cols-2" : ""
            }${
              data?.columns === 3 ? "sm:grid-cols-3" : ""
            } mb-5 w-[90%] mx-auto`}
          >
            {data?.columnItem?.map((item, index) => {
              return (
                <div key={index}>
                  <PortableText
                    content={item._rawCol}
                    className={`portable-editor ${
                      data.textColor === "dark" ? "text-assent" : "text-white"
                    } block-content max-w-none text-base font-semibold`}
                    serializers={{
                      ul: ({ children }) => (
                        <ul className="p-0 m-0 ml-3 -mt-5 list-none">
                          {children}
                        </ul>
                      ),
                      li: ({ children }) => (
                        <li className="p-0 ml-7 my-1 before:content-[''] before:h-[8px] before:w-[8px] before:rounded-full before:border before:border-[#ccc] before:inline-block before:mr-4 ">
                          {children}
                        </li>
                      ),
                    }}
                  />
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
}
